<template>
  <div>
    <!--begin::customer-->
    <div>
<!--    <div class="card card-custom">-->
<!--      <div class="card-header flex-wrap border-0 pt-6 pb-0">-->
<!--        <div class="card-title m-0">-->
          <h3 class="card-label">
            {{ $t('sales_quotation_requests.sales_quotation_requests') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
<!--        </div>-->
<!--      </div>-->
      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('sales_quotation_requests.title') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.title" class="form-control" :class="validation && validation.title ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.title" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.title[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('sales_quotation_requests.customer') }}<span class="text-danger">*</span></label>
                <div class="form-group row">
                  <div class="col-sm-8">
                    <multiselect
                        id="customer_id"
                        v-model="customer"
                        :placeholder="$t('sales_quotation_requests.customer')"
                        label="fullname"
                        track-by="id"
                        :options="customers"
                        :multiple="false"
                        :taggable="false"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false"
                        @search-change="getCustomers($event)">
                    </multiselect>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('sales_quotation_requests.customer') }}.</span>
                    <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
                                                {{ validation.customer_id[0] }}
                                            </span>
                  </div>
                  <div class="col-sm-4">
                    <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <b-card no-body v-if="customer" class="mt-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-50 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                {{ $t('customer_data_details')}}
              </b-button>
            </b-card-header>

            <b-collapse id="accordion-50" accordion="my-accordion-1" role="tabpanel">
              <b-card-body class="row">
                <div class="col-lg-12 mb-5">
                  <p><b>{{ $t('name')}}: </b> {{customer.fullname}}</p>
                  <p><b>{{ $t('mobile')}}: </b> {{customer.mobile}}</p>
                  <p><b>{{ $t('email')}}: </b> {{customer.email}}</p>
                  <p><b>{{ $t('tax_no')}}: </b> {{customer.tax_register}}</p>
                </div>
                <div class="col-md-12 mb-5">
                  <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                    <h6 class="my-auto text-white">{{ $t('customers.balances') }}</h6>
                  </div>
                  <table class="table table-row-bordered">
                    <thead>
                    <tr>
                      <th>{{ $t('customers.balance') }}</th>
                      <th>{{ $t('customers.debit') }}</th>
                      <th>{{ $t('customers.currency') }}</th>
                      <th>{{ $t('customers.last_validated_date') }}</th>
                      <th></th>
                    </tr>

                    </thead>
                    <tbody>
                    <template v-if="customer.balances && customer.balances.length > 0">
                      <tr v-for="(row, index) in customer.balances" :key="index">
                        <td>{{row.credit}}</td>
                        <td>{{row.debit}}</td>
                        <td>{{row.currency_name}}</td>
                        <td>{{ row.last_validated_date }}</td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="4">{{ $t('no_balances') }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_quotation_requests.sales_quotation_request_code') }}</label>
                <input type="text" v-model="data.sales_quotation_request_code" class="form-control" :class="validation && validation.sales_quotation_request_code ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.sales_quotation_request_code" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.sales_quotation_request_code[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_quotation_requests.sales_quotation_request_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.sales_quotation_request_date" class="form-control" :class="validation && validation.sales_quotation_request_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.sales_quotation_request_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.sales_quotation_request_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_quotation_requests.sales_request_due_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.sales_request_due_date" class="form-control" :class="validation && validation.sales_request_due_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.sales_request_due_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.sales_request_due_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_quotation_requests.branch') }}<span class="text-danger">*</span></label>
                <!-- <select name="" id="branch" v-model="data.branch_id" type="text" class="custom-select" :class="validation && validation.branch_id ? 'is-invalid' : ''">
                    <option v-for="(row, index) in branches" :value="row.id" :key="index">{{ row.name }}</option>
                </select> -->
                <div class="input-group mb-3">
                  <multiselect v-model="branch"
                               :placeholder="$t('sales_quotation_requests.branch')"
                               label="name"
                               track-by="id"
                               :options="branches"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.branch_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="f_status" v-model="data.status" type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
              </div>
            </div>
          </div>
        </div>
      </div>




        <div class="row mt-5">
            <div class="col-md-12">
              <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803">
                <h6 class="my-auto text-white">{{ $t('sales_quotation_requests.items') }}</h6>
                <button type="button" class="btn btn-primary btn-sm" @click="addItemRowToList">{{ $t('add_more') }}</button>
              </div>
              <div class="table-responsive bg-white pb-5">
                <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
                  <thead>
                  <tr>
                    <th width="250px">{{ $t('items.item_details') }}</th>
                    <th width="300px">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('sales_quotation_requests.item') }}</span>
                        <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                          <b-icon icon="plus"></b-icon>
                        </a>
                      </div>
                    </th>
                    <th>{{ $t('sales_quotation_requests.qty') }}</th>
                    <th></th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in items_list" :key="index">
                    <td>
                      <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                        <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                        <div class="pl-2 pr-2 text-dark">
                          <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                          <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                          <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                          <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                          <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.sum_available_qty }}</p>
                        </div>
                      </a>
                    </td>
                    <td>
                      <div class="d-flex search-item-group"  v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('sales_quotation_requests.item')">
                        <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                          <option value="name">{{ $t('items.name') }}</option>
                          <option value="sku_code">{{ $t('items.sku_code') }}</option>
                        </select>
                        <multiselect v-model="row.item"
                                     :placeholder="$t('sales_quotation_requests.item')"
                                     :label="row.f_by? row.f_by:'name'"
                                     track-by="id"
                                     :options="items"
                                     :multiple="false"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false"
                                     :internal-search="false"
                                     @search-change="getItems(row.f_by, $event)">
                        </multiselect>
                      </div>
<!--                      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('sales_quotation_requests.item') }}.</span>-->

                    </td>
                    <td><input v-model="row.qty" type="number" class="form-control"></td>
                    <td>
                      <v-icon style="color: #dc3545;" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>

        <div class="mt-5">
          <b-tabs content-class="mt-3" class="nav-custom-link">
            <b-tab :title="$t('notes')" active>
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_quotation_requests.notes') }}</label>
                    <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                    <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.notes[0] }}
                                </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_quotation_requests.attachment') }}</label>
                    <div class="form-group">
                      <upload-file
                          @file="listenerAttachment"
                          :inner-id="'attachment'"
                          :placeholder="$t('sales_quotation_requests.attachment')"
                          :upload="dir_upload"
                          :start-link="'base'"
                          v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                      </upload-file>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('customers.add_new_customer')">
      <customer-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewCustomer"></customer-form>
    </b-modal>
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ItemForm from "@/view/content/forms/ItemForm";
import {mapGetters, mapState} from "vuex";
import CustomerForm from "@/view/content/forms/CustomerForm.vue";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "form-sales-quotation-requests",
  components: {'customer-form': CustomerForm, 'item-form': ItemForm},
  data() {
    return {
      mainRoute: 'sales/sales_quotation_requests',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'sales',


      data: {
        title: null,
        sales_quotation_request_code: null,
        attachment: null,
        status: 1,
        sales_quotation_request_date: null,
        customer_id: null,
        branch_id: null,
        sales_request_due_date: null,
        notes: null,
      },
      idEditing: this.$route.params.id,
      isEditing: false,
      reloadUploadAttachment: true,
      attachment_url: '',

      status_list: [],

      validation: null,
      items_list_form: {id: null, item: null, qty: null},
      items_list: [],
      items: [],
      branches: [],
      customers: [],
      customer: null,
      branch: null,
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    customer: function (val) {
      if (val) {
        this.data.customer_id = val.id;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      }
    },
  },
  methods: {
    ...timeZoneStructure,
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => parseFloat(row.qty) > 0);

      ApiService.post(`${this.mainRoute}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'sales-quotation-requests.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => parseFloat(row.qty) > 0);

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'sales-quotation-requests.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.title = response.data.data.title;
        this.data.sales_quotation_request_code = response.data.data.sales_quotation_request_code;
        this.data.attachment = response.data.data.attachment;
        this.data.status = response.data.data.status;
        this.data.sales_quotation_request_date = response.data.data.sales_quotation_request_date;
        this.data.customer_id = response.data.data.customer_id;
        this.data.branch_id = response.data.data.branch_id;
        this.data.sales_request_due_date = response.data.data.sales_request_due_date;
        this.data.notes = response.data.data.notes;

        this.customer = response.data.data.customer;
        this.branch = response.data.data.branch;


        this.attachment_url = response.data.data.attachment_url;
        this.items_list = response.data.data.items_list;

        if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
          this.addItemRowToList();
        }
        this.reloadUploadAttachment = true;
      });
    },

    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status", {params: {type: 'purchases_quotation_requests'}}).then((response) => {
        this.status_list = response.data.data;
      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter, 'item_type': 1}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    async getCustomers(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },

    loadOptions() {
    },


    addItemRowToList() {
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, item: null, qty: null};
    },
    removeItemRowFromList(index = 0) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
    },
    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },

    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },

    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
        }
      })
    },

    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    getDataAfterCreateNewCustomer(object) {
      let promise = this.getCustomers();
      Promise.all([promise]).then(() => {
        this.data.customer_id = object.id;
      })
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/19").then((response) => {
        this.data.sales_quotation_request_code = response.data.data.code;
      });
    },

    defaultDataForUser(){
      ApiService.get(this.mainRouteDependency +"/default_data_for_user").then((response) => {
        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;

      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_quotation_requests"), route: '/sales/sales-quotation-requests'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    // this.getItems();
    this.getStatus();
    this.getBranches();
    this.getCustomers();

    if (this.idEditing) {
      this.getData();
    } else {
      if (this.items_list.length <= 0) {
        this.addItemRowToList();
      }
      this.getCode();
      this.defaultDataForUser();

      this.getTimeZone().then((res)=>{
        this.data.sales_quotation_request_date = res;
        this.data.sales_request_due_date = res;
      });

      // this.data.sales_quotation_request_date = new Date().toISOString().slice(0, 10);
      // this.data.sales_request_due_date = new Date().toISOString().slice(0, 10);
    }

  },
};
</script>